import { cdnFileUrl } from "~/lib/environment";

export const constants = {
  // お問い合わせフォーム
  contactForm: {
    url: "https://forms.gle/w4t8heYVDb9mxBaC8",
  },
  videoUrls: {
    reachToNotification: cdnFileUrl("/fussy/videos/reach-to-notification.mp4"),
  },
} as const;
