import { useEffect, useState } from "react";

import { useFirebaseAuth } from "~/lib/client/firebase";
import { Sdk } from "~/lib/graphql/client";
import { createGraphqlClient } from "~/lib/graphql/graphqlClient";

export function useGraphqlClient(): Sdk | null | undefined {
  const [firebaseUser] = useFirebaseAuth();
  const [client, setClient] = useState<Sdk | null | undefined>(undefined);

  useEffect(() => {
    (async () => {
      if (!firebaseUser) {
        const client = await createGraphqlClient();
        setClient(client);
        return;
      }

      const token = await firebaseUser.getIdToken();
      const client = await createGraphqlClient(token);
      setClient(client);
    })();
  }, [firebaseUser]);

  return client;
}

export function useGraphqlClientWithoutAuth(): Sdk | null | undefined {
  const [client, setClient] = useState<Sdk | null | undefined>(undefined);

  useEffect(() => {
    (async () => {
      const client = await createGraphqlClient();
      setClient(client);
    })();
  }, []);

  return client;
}

export function useGraphqlClientWithAuth(): Sdk | null | undefined {
  const [client, setClient] = useState<Sdk | null | undefined>(undefined);
  const [firebaseUser] = useFirebaseAuth();

  useEffect(() => {
    if (firebaseUser === undefined) {
      return;
    }

    if (firebaseUser === null) {
      setClient(null);
      return;
    }

    firebaseUser
      .getIdToken()
      .then(createGraphqlClient)
      .then(setClient)
      .catch(console.error);
  }, [firebaseUser]);

  return client;
}
