import PersonIcon from "@mui/icons-material/Person";
import { Avatar, SxProps } from "@mui/material";

import { useAppContext } from "~/pages/_app";

export const UserIcon = ({
  size,
  imageUrl,
}: {
  size?: number;
  imageUrl?: string;
}) => {
  const iconSize: SxProps = { width: size, height: size };
  return (
    <>
      {imageUrl ? (
        <Avatar src={imageUrl} sx={iconSize} />
      ) : (
        <Avatar sx={iconSize}>
          <PersonIcon />
        </Avatar>
      )}
    </>
  );
};

const CurrentUserIcon = ({ size }: { size?: number }) => {
  const { currentUser } = useAppContext();

  if (!currentUser) {
    return <div />;
  }

  return <UserIcon size={size} imageUrl={currentUser.iconUrl ?? undefined} />;
};

export default CurrentUserIcon;
