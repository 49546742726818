import Link from "next/link";
import { FC } from "react";

const FussyLogo = () => (
  <svg
    width="400"
    height="129"
    viewBox="0 0 400 129"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M39.7068 11.4571L55.306 25.1768L70.9052 11.4571H99.6962V45.5289L110.648 58.0483L99.6962 70.5677V104.64H67.234L55.306 116.135L43.378 104.64H10.9158V74.421L23.9874 58.0483L10.9158 41.6756V11.4571H39.7068ZM69.071 0L55.306 12.6075L41.5438 0H0V43.6037L12.0119 58.0512L0 72.4958V116.1H41.5438L55.3088 128.707L69.0738 116.1H110.618V72.4958L122.629 58.0483L110.618 43.6008V0H69.0738H69.071Z"
      fill="#E03500"
    />
    <path
      d="M160.692 92.9509C161.179 87.0568 161.505 75.7821 161.505 64.9331V60.8341C161.505 49.7283 161.179 38.5415 160.692 32.5595L169.804 32.6451C172.813 32.7307 175.659 32.7307 178.345 32.7307C185.91 32.7307 193.342 32.5595 197.574 32.3906V42.6405C197.248 42.8117 196.321 43.2466 193.508 43.0662C189.906 42.8372 185.015 41.8702 180.136 41.8702C177.939 41.8702 175.58 41.9558 173.302 42.127C173.139 47.3364 172.978 53.5728 172.978 59.7237C181.193 59.6381 188.353 59.2958 190.813 58.9534V69.8025C188.434 69.4624 180.625 69.1177 172.978 69.0321C173.06 78.6852 173.383 87.9104 173.791 92.9509H160.694H160.692Z"
      fill="black"
    />
    <path
      d="M224.91 94.2358C212.382 94.2358 204.98 87.6593 204.98 76.6391V32.6484H217.508C217.019 38.5425 216.696 49.8172 216.696 60.6662V71.1729C216.696 80.2269 220.031 84.3259 227.351 84.3259C231.826 84.3259 236.3 82.6188 240.855 79.1165V32.6484H253.139C252.65 38.5425 252.324 49.8172 252.324 60.6662V64.7653C252.324 75.8711 252.65 87.0602 253.139 92.8687H242.97C242.644 91.5871 242.237 89.1097 241.831 86.2066C238.523 89.7181 234.795 92.3112 230.768 93.5534C229.164 94.0484 227.188 94.2381 224.91 94.2381V94.2358Z"
      fill="black"
    />
    <path
      d="M280.442 94.6188C273.741 95.4909 266.694 92.3981 261.406 88.7247L265.555 77.1933C271.249 81.8915 277.107 84.6257 282.15 84.6257C286.866 84.6257 289.633 82.1482 289.633 77.878C289.633 74.2902 287.844 72.2407 283.207 70.1889L274.911 66.601C266.694 63.0988 262.79 57.8038 262.79 50.1147C262.79 44.135 265.359 39.7653 269.542 36.1914C273.829 32.5295 277.089 31.0491 282.482 30.5147C288.395 29.9271 294.773 31.9651 299.883 35.2522L296.061 46.4413C295.735 46.5269 295.334 46.5269 295.008 46.4413C295.008 46.4413 290.014 41.5118 281.949 40.6328C277.182 40.1146 274.095 43.0246 274.095 47.2093C274.095 50.6282 275.968 52.761 280.929 54.9817L289.226 58.5718C297.28 61.9885 300.941 67.1979 300.941 74.887C300.941 81.0379 298.432 85.4862 294.27 88.8959C290.259 92.1807 286.388 93.8439 280.442 94.6165V94.6188Z"
      fill="black"
    />
    <path
      d="M324.98 94.6188C318.279 95.4909 311.232 92.3981 305.944 88.7247L310.093 77.1933C315.787 81.8915 321.645 84.6257 326.688 84.6257C331.404 84.6257 334.171 82.1482 334.171 77.878C334.171 74.2902 332.382 72.2407 327.745 70.1889L319.449 66.601C311.232 63.0988 307.328 57.8038 307.328 50.1147C307.328 44.135 309.897 39.7653 314.08 36.1914C318.367 32.5295 321.627 31.0491 327.02 30.5147C332.933 29.9271 339.311 31.9651 344.422 35.2522L340.599 46.4413C340.273 46.5269 339.872 46.5269 339.546 46.4413C339.546 46.4413 334.552 41.5118 326.487 40.6328C321.72 40.1146 318.634 43.0246 318.634 47.2093C318.634 50.6282 320.506 52.761 325.467 54.9817L333.764 58.5718C341.818 61.9885 345.479 67.1979 345.479 74.887C345.479 81.0379 342.97 85.4862 338.808 88.8959C334.797 92.1807 330.926 93.8439 324.98 94.6165V94.6188Z"
      fill="black"
    />
    <path
      d="M368.271 92.8687C368.676 88.2561 368.923 80.3981 369.002 72.2833C361.764 58.5311 353.447 46.9396 350.601 39.1833C349.823 36.6156 349.967 32.9908 350.048 32.6484H362.25C363.634 36.6642 368.839 48.2789 375.836 63.2293C382.344 48.2789 387.062 36.6642 388.362 32.6484H399.915C399.996 32.9908 400.252 37.3767 399.17 39.4725C395.112 46.9858 387.957 58.6144 380.553 72.1977C380.634 80.4814 380.879 88.3417 381.286 92.8687H368.271Z"
      fill="black"
    />
  </svg>
);

type Props = {
  onClick?: () => void;
};

const FussyLogoButton: FC<Props> = ({ onClick }) => {
  return onClick == undefined ? (
    <Link href={"/"}>
      <FussyLogo />
    </Link>
  ) : (
    <div onClick={onClick}>
      <FussyLogo />
    </div>
  );
};

export { FussyLogo, FussyLogoButton };
