import { css } from "@emotion/react";
import MenuIcon from "@mui/icons-material/Menu";
import PersonIcon from "@mui/icons-material/Person";
import { Avatar, Theme, useTheme } from "@mui/material";
import Link from "next/link";
import { useRouter } from "next/router";
import { TbBellRingingFilled } from "react-icons/tb";
import { TbBell } from "react-icons/tb";

import { FussyLogo } from "~/components/svg/fussyLogo";
import {
  fixedHeight,
  fixedWidth,
  flexRow,
  mediaQueryIsMobile,
} from "~/lib/client/design";
import useUnReadCount from "~/lib/client/hooks/notification/useUnReadCount";
import { useAppContext } from "~/pages/_app";

const ROOT_PAGES = [
  {
    path: "/",
    name: "Discovery",
  },
  {
    path: "/category",
    name: "Fave",
  },
  {
    path: "/database",
    name: "Database",
  },
  {
    path: "/notification",
    name: "Notification",
  },
  {
    path: "/notification/[id]",
    name: "Notification",
    link: "/notification",
  },
  {
    path: "/info",
    name: "Information",
  },
  {
    path: "/info/[slug]",
    name: "Information",
    link: "/info",
  },
  {
    path: "/quest",
    name: "Quest",
  },
  {
    path: "/quest/[id]",
    name: "Quest",
    link: "/quest",
  },
  {
    path: "/me",
    name: "MyPage",
  },
  {
    path: "/ad/[id]",
    name: "　",
  },
  {
    path: "/raid",
    name: "Project",
  },
  {
    path: "/raid/[id]",
    name: "Project",
  },
  {
    path: "/category/[id]/membership",
    name: "Membership",
  },
  {
    path: "/category/[id]/membership/[membershipId]",
    name: "Membership",
  },
  {
    path: "/partner/admin",
    name: "Partner Admin",
  },
] as const;

const Header = ({ onMenuClick }: { onMenuClick?: () => void }) => {
  const theme = useTheme();
  const router = useRouter();
  const pageTitle = ROOT_PAGES.find((p) => p.path === router.pathname);
  const { currentUser } = useAppContext();

  return (
    <header css={headerRootStyle(theme)}>
      {/*1段目*/}
      <section css={header1stSectionStyle}>
        {/*左側*/}
        <div css={menuAndLinkStyle}>
          <MenuIcon
            onClick={onMenuClick}
            sx={{
              width: "32px",
              height: "32px",
            }}
          />
          <Link css={logoContainerStyle()} href={"/"}>
            <FussyLogo />
          </Link>
        </div>
        {/*右側*/}
        <div>
          {currentUser === undefined ? (
            <div />
          ) : currentUser === null ? (
            <div css={notLoginedLinksStyle(theme)}>
              <Link href={"/account/login"}>ログインする</Link>
              <Link href={"/account/signup"}>登録する</Link>
            </div>
          ) : (
            <div
              css={css`
                ${flexRow};
                gap: 1rem;
                align-items: center;
              `}
            >
              <NotificationBell />
              <Link href={"/me"}>
                {currentUser.iconUrl ? (
                  <Avatar src={currentUser.iconUrl} />
                ) : (
                  <Avatar>
                    <PersonIcon />
                  </Avatar>
                )}
              </Link>
            </div>
          )}
        </div>
      </section>
      {/*2段目*/}
      <div css={headerTopStyle(theme)}>
        {pageTitle?.name && <h1 css={h1Style}>{pageTitle.name}</h1>}
      </div>
    </header>
  );
};

const headerRootStyle = (theme: Theme) => css``;

const header1stSectionStyle = css`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 1rem 2rem;

  ${mediaQueryIsMobile(css`
    padding: 0.5rem 1rem;
  `)};
`;

const menuAndLinkStyle = css`
  ${flexRow};
  gap: 1rem;
  align-items: center;

  ${mediaQueryIsMobile(css`
    gap: 0.5rem;
  `)};
`;

const headerTopStyle = (theme: Theme) => css`
  ${flexRow};
  justify-content: space-between;
  align-items: center;
  background: ${theme.palette.background.paper};
`;

const logoContainerStyle = () => css`
  ${fixedWidth("108px")};
  ${fixedHeight("33px")};

  svg {
    width: 100%;
    height: 100%;
  }

  ${mediaQueryIsMobile(css`
    ${fixedWidth("98px")};
    ${fixedHeight("30px")};
  `)};
`;

const h1Style = css`
  font-size: 44px;
  padding-left: 4rem;

  ${mediaQueryIsMobile(css`
    font-size: 30px;
    padding-left: 1rem;
    padding-top: 1.5rem;
  `)};
`;

const notLoginedLinksStyle = (theme: Theme) => css`
  ${flexRow};
  gap: 1rem;

  a {
    padding: 0.5rem 1rem;
    border-radius: 8px;
    background-color: ${theme.palette.background.paper};
    color: ${theme.palette.text.primary};
    text-decoration: none;
    font-weight: 600;

    ${mediaQueryIsMobile(css`
      display: none;
    `)};
  }
`;

const NotificationBell = () => {
  const { hasUnRead } = useUnReadCount();
  const theme = useTheme();

  return (
    <Link href={"/notification"}>
      {hasUnRead ? (
        <TbBellRingingFilled size={32} css={hasUnReadCircleStyle(theme)} />
      ) : (
        <TbBell size={32} />
      )}
    </Link>
  );
};

const hasUnReadCircleStyle = (theme: Theme) => css`
  color: ${theme.palette.error.main};
`;

export { Header };
