import { css } from "@emotion/react";
import {
  Box,
  Divider,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Skeleton,
} from "@mui/material";
import Link from "next/link";
import { ReactNode, useState } from "react";
import { match } from "ts-pattern";

import CurrentUserIcon from "~/components/common/CurrentUserIcon";
import ServiceFooter from "~/components/common/ServiceFooter";
import { Header } from "~/components/layout/header";
import { flexColumn } from "~/lib/client/design";
import { currentUserLoadingStatus, useAppContext } from "~/pages/_app";

const YTLayout = ({ children }: { children: ReactNode }) => {
  const [isOpen, setIsOpen] = useState(false);
  const closeDrawer = () => setIsOpen(false);
  const toggleDrawer = () => setIsOpen(!isOpen);
  const { currentUser } = useAppContext();

  return (
    <div role={"layout"}>
      <Header onMenuClick={toggleDrawer} />
      {children}
      <ServiceFooter />
      <Drawer open={isOpen} onClose={closeDrawer}>
        <Box sx={{ width: 250 }} role="presentation" onClick={closeDrawer}>
          <List>
            <ListItem>
              {match(currentUserLoadingStatus(currentUser))
                .with("loading", () => <div />)
                .with("notLoggedIn", () => (
                  <div css={notLogginStyle}>
                    <Link href={"/account/login"}>ログインする</Link>
                    <Link href={"/account/signup"}>登録する</Link>
                  </div>
                ))
                .with("loggedIn", () => (
                  <Link href={"/me"} css={avatarContainerStyle}>
                    <CurrentUserIcon />
                    <p>{currentUser?.name}</p>
                  </Link>
                ))
                .exhaustive()}
            </ListItem>
            <Divider />
            {match(currentUserLoadingStatus(currentUser))
              .with("loading", () => <Skeleton variant={"rounded"} />)
              .with("notLoggedIn", () => <div />)
              .with("loggedIn", () => (
                <>
                  <ListItem>
                    <ListItemButton href={"/me"}>
                      <ListItemText primary={"MyPage"} />
                    </ListItemButton>
                  </ListItem>
                  <ListItem>
                    <ListItemButton href={"/notification"}>
                      <ListItemText primary={"Notification"} />
                    </ListItemButton>
                  </ListItem>
                </>
              ))
              .exhaustive()}
            <ListItem>
              <ListItemButton
                href={"https://fussy.gitbook.io/fussy-user-guide"}
                target="_blank"
              >
                <ListItemText primary={"User Guide"} />
              </ListItemButton>
            </ListItem>
            <ListItem>
              <ListItemButton href={"/info"}>
                <ListItemText primary={"News"} />
              </ListItemButton>
            </ListItem>
            <ListItem>
              <ListItemButton href={"https://fussy-inc.com"} target="_blank">
                <ListItemText primary={"Official"} />
              </ListItemButton>
            </ListItem>
          </List>
        </Box>
      </Drawer>
    </div>
  );
};

const avatarContainerStyle = css`
  width: 100%;
  background-color: #fff;
  padding: 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
`;

const notLogginStyle = css`
  width: 100%;
  background-color: #fff;
  border-radius: 8px;
  ${flexColumn};
  gap: 8px;
  padding: 16px 8px 16px 16px;

  a {
    width: 100%;
    padding: 8px;
    border-radius: 8px;
    text-align: center;
    background-color: #f5f5f5;

    &:hover {
      background-color: #e0e0e0;
    }
  }
`;

export default YTLayout;
