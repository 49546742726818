import { FC, ReactNode } from "react";
import { match } from "ts-pattern";

import YTLayout from "~/components/layout/YTLayout";

const layoutTypes = ["YTLayout"] as const;

const EmptyLayout: FC<{
  children: ReactNode;
}> = ({ children }) => {
  return <div role={"layout"}>{children}</div>;
};

export const Layout = ({
  children,
  layoutType = null,
}: {
  children: ReactNode;
  layoutType?: (typeof layoutTypes)[number] | null;
}) => {
  const _L = match(layoutType)
    .with(null, () => EmptyLayout)
    .with("YTLayout", () => YTLayout)
    .exhaustive();

  return <_L>{children}</_L>;
};
