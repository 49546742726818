import { css } from "@emotion/react";
import { Divider, Theme, useTheme } from "@mui/material";
import Link from "next/link";

import { constants } from "~/lib/constants";

const ServiceFooter = () => {
  const theme = useTheme();
  return (
    <footer css={rootStyle(theme)}>
      <Divider color={"white"} flexItem />
      <div
        css={css`
          padding-top: 2rem;
        `}
      >
        <Link
          href={
            "https://fussy-inc.notion.site/12d53f4ffddb456eac3bcf87d4108458"
          }
          css={css`
            margin: 4px;
          `}
        >
          利用規約
        </Link>{" "}
        /
        <Link
          href={
            "https://fussy-inc.notion.site/17ddfc821bce4a66b55050723257ace3"
          }
        >
          プライバシーポリシー
        </Link>{" "}
        /<Link href={constants.contactForm.url}>お問い合わせ</Link>
      </div>
      <p>© 2022-2024 FUSSY Inc.</p>
    </footer>
  );
};

const rootStyle = (theme: Theme) => css`
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 12px;
  color: ${theme.palette.text.secondary};
  margin-top: 5rem;
  padding: 1rem 1rem 20vh;
`;

export default ServiceFooter;
